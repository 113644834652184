import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["controller"]
  static values = {
    sellingChannelsColumnsNames: Object
  }

  selectSellingChannelFields(event) {
    event.preventDefault();
    event.stopPropagation();
    const clickedSellingChannelName = event.currentTarget.dataset.sellingChannelName;
    const sellingChannelColumnsNames = this.sellingChannelsColumnsNamesValue[clickedSellingChannelName];
    if (!sellingChannelColumnsNames?.length > 0) return;

    this.controllerTargets.forEach(target => target.controller.unselectAll());
    this.controllerTargets.forEach(target => target.controller.checkElements(sellingChannelColumnsNames));
  }
}
