import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pageHeader", "sectionHeader", "valueForm", "searchInput", "scopesForm"]
  static values = {
    sellingChannelsColumnsNames: Object,
  }

  connect() {
    this.searchedName = null;
    this.searchedScope = null;
  }

  #correspondingSectionHeader(headerId){
    return this.sectionHeaderTargets.find(sectionHeader => sectionHeader.id == headerId)
  }

  scrollToSection(event) {
    event.preventDefault();
    const headerId = event.currentTarget.dataset.sectionHeaderId;
    window.scrollTo({
      behavior: 'smooth',
      top:
        this.#correspondingSectionHeader(headerId).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        this.pageHeaderTarget.getBoundingClientRect().height,
    })
    // this.#correspondingSectionHeader(headerId).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  displayResults(event) {
    window.scrollTo({
      behavior: 'smooth',
      top: 110,
    })
    this.#hideEveryone();
    if (event.currentTarget == this.scopesFormTarget) {
      this.searchedScope = Object.fromEntries(new FormData(this.scopesFormTarget).entries())['search[scope]'];
      if (this.searchedScope === "all") this.searchedScope = null;
    }
    if (event.currentTarget == this.searchInputTarget) {
      this.searchedName = this.searchInputTarget.value.toLowerCase();
      if (this.searchedName === "") this.searchedName = null;
    }
    var targets = this.valueFormTargets;
    if (this.searchedScope) {
      targets = targets.filter(target => [this.searchedScope, ''].includes(target.dataset.scope?.toLowerCase()))
    }
    if (this.searchedName) {
      targets = targets.filter(target => target.dataset.label.toLowerCase().includes(this.searchedName))
    }
    targets.forEach(target => target.classList.remove('d-none'));
    this.sectionHeaderTargets.forEach((target) => {
      const visibleValueForms = this.valueFormTargets.filter(t => t.dataset.category == target.id && !t.classList.contains('d-none'));
      if (visibleValueForms.length > 0) target.classList.remove('d-none');
    })
  }

  showSellingChannelFields(event) {
    event.preventDefault();
    event.stopPropagation();
    const clickedSellingChannelName = event.currentTarget.dataset.sellingChannelName;
    const sellingChannelColumnsNames = this.sellingChannelsColumnsNamesValue[clickedSellingChannelName];
    if (!sellingChannelColumnsNames?.length > 0) return;

    this.#hideEveryone();
    this.scopesFormTarget.reset();
    this.searchInputTarget.value = "";
    const targets = this.valueFormTargets.filter(target => sellingChannelColumnsNames.includes(target.dataset.label))
    targets.forEach(target => target.classList.remove('d-none'));
    this.sectionHeaderTargets.forEach((target) => {
      const visibleValueForms = this.valueFormTargets.filter(t => t.dataset.category == target.id && !t.classList.contains('d-none'));
      if (visibleValueForms.length > 0) target.classList.remove('d-none');
    })
  }

  #hideEveryone() {
    this.sectionHeaderTargets.forEach(el => el.classList.add('d-none'));
    this.valueFormTargets.forEach(el => el.classList.add('d-none'));
  }
}
