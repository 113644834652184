import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "element" ]
  static values = {
    type: String,
  }

  get filtersForm() {
    return document.querySelector('form.js-filters-form');
  }

  get filtersFormSelect() {
    return this.filtersForm.querySelector(`.js-${this.typeValue}-select`);
  }
  get filtersFormSelectOptions() {
    return Array.from(this.filtersFormSelect.options);
  }

  get filtersFormSubmit() {
    return this.filtersForm.querySelector('.js-filters-form-submit');
  }

  groupedToggle() {
    const checked = !Array.from(this.elementTargets).every(el => el.checked);
    this.elementTargets.forEach(el => el.checked = checked);

    let selectedValues;
    if (this.typeValue === 'product-attribute') {
      selectedValues = this.elementTargets.map(el => el.nextElementSibling.dataset.attribute);
    }
    if (this.typeValue === 'channel') {
      selectedValues = this.elementTargets.map(el => el.nextElementSibling.dataset.channelId);
    }
    if (this.typeValue === 'field') {
      selectedValues = this.elementTargets.map(el => el.nextElementSibling.dataset.fieldId);
    }

    selectedValues.forEach(value => this.toggleFiltersFormSelectOption(value, checked))
  }

  unselectAll() {
    this.elementTargets.forEach(el => el.checked = false);
    this.filtersFormSelectOptions.forEach(option => option.selected = false);
  }

  checkElements(values) {
    let selectedElements = this.elementTargets.filter(el => values.includes(el.nextSibling.dataset.fieldName));
    selectedElements.forEach(el => el.checked = true);
    const selectedValues = selectedElements.map(el => el.value);
    
    selectedValues.forEach(value => this.toggleFiltersFormSelectOption(value, true))
  }

  connect() {
    this.element['controller'] = this;
    const labels = this.element.querySelectorAll('label');
    labels.forEach(label => {
      label.addEventListener('click', event => {
        event.preventDefault();
        const associatedInput = event.currentTarget.querySelector('input[type="checkbox"]');
        associatedInput.checked = !associatedInput.checked;
      });
    })
  }

  toggle() {
    let selectedValue;
    if (this.typeValue === 'product-attribute') {
      selectedValue = event.currentTarget.dataset.attribute;
    }
    if (this.typeValue === 'channel') {
      selectedValue = event.currentTarget.dataset.channelId;
    }
    if (this.typeValue === 'field') {
      selectedValue = event.currentTarget.dataset.fieldId;
    }

    this.toggleFiltersFormSelectOption(selectedValue);
  }

  toggleFiltersFormSelectOption(value, checked = null) {
    this.filtersFormSelectOptions.forEach(option => {
      if (option.value === value) {
        let selected = checked;
        if (selected === null) selected = !option.selected
        option.selected = selected;
      }
    })
  }
}
